@use '@angular/material' as mat;
@import '../node_modules/@angular/material/theming';
$custom-typography: mat.define-legacy-typography-config( $font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";' );

/* You can add global styles to this file, and also import other style files */
/*@import '@angular/material/prebuilt-themes/deeppurple-amber.css';*/
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Round");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import url("https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.css");
@import './app/directives/mat-table-responsive.directive';
@import '~bootstrap/dist/css/bootstrap.css';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$mat-primary: ( main: #337dff, lighter: #b9d4ed, darker: #0b51ae, 200: #337dff, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #387073;
  --accent-lighter-color: #c3d4d5;
  --accent-darker-color: #245356;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: ( main: #6c767e, lighter: #c3d4d5, darker: #245356, 200: #387073, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: ( main: #ff0000, lighter: #ffb3b3, darker: #ff0000, 200: #ff0000, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);


$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-legacy-component-themes($theme);
/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}*/

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #146ec3;
}

.btn-high {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #337dff !important;
  border-color: #7095c8 !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: #ffffff !important;
  font-size: small !important;

  &:hover {
    /*color: #4e629d !important;
    background-color: #f2faff !important;*/
    border-color: #026bff !important;
    box-shadow: 0 2px 5px 0 #6db7ff !important;
  }

  &:active {
    background-color: #337dff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #ffffff !important;
  }

  &:focus {
    /* background-color: #f2faff !important;
         color: #337dff !important;
    */
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #7095c8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}

.btn-med {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #f2faff !important;
  border-color: #7095c8 !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #d2d2d6 !important;
  color: #4e629d !important;
  font-size: small !important;

  &:hover {
    color: #4e629d !important;
    background-color: #f2faff !important;
    border-color: #026bff !important;
    box-shadow: 0 2px 5px 0 #6db7ff !important;
  }

  &:active {
    background-color: #337dff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #ffffff !important;
  }

  &:focus {
    background-color: #f2faff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #337dff !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #7095c8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}


.btn-lightgreen {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-size: small !important;


  &:hover {
    color: #3c763d !important;
    background-color: #d8f0e4 !important;
    border-color: #d6e9c6 !important;
    box-shadow: 0 2px 5px 0 #d8f0e4 !important;
  }

  &:active {
    background-color: #b3e0b3 !important;
    outline-style: solid !important;
    outline-color: #70c89a !important;
    outline-width: 2px !important;
    color: #37574c !important;
  }

  &:focus {
    background-color: #f3fff2 !important;
    outline-style: solid !important;
    outline-color: #71bf62 !important;
    outline-width: 2px !important;
    color: #37574c !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #70c88a !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}


.btn-lightyellow {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
  color: #856404 !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-size: small !important;


  &:hover {
    color: #856404 !important;
    background-color: #fef7e0 !important;
    border-color: #e8e9c6 !important;
    box-shadow: 0 2px 5px 0 #eef0d8 !important;
  }

  &:active {
    background-color: #fff3cd !important;
    outline-style: solid !important;
    outline-color: #70c89a !important;
    outline-width: 2px !important;
    color: #37574c !important;
  }

  &:focus {
    background-color: #fffef2 !important;
    outline-style: solid !important;
    outline-color: #bfbd62 !important;
    outline-width: 2px !important;
    color: #545737 !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #c4c870 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}


.btn-low {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-style: solid !important;
  border-color: #d2d2d6 !important;
  border-width: 1px !important;
  color: #337dff !important;
  font-size: small !important;

  &:hover {
    color: #4e629d !important;
    background-color: #f2faff !important;
    border-color: #026bff !important;
    box-shadow: 0 2px 5px 0 #6db7ff !important;
  }

  &:active {
    background-color: #337dff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #ffffff !important;
  }

  &:focus {
    background-color: #f2faff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #337dff !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #7095c8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}

.btn-low-highlight {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-style: solid !important;
  /*border-color: #d2d2d6 !important;*/
  border-width: 1px !important;
  color: #337dff !important;
  font-size: small !important;
  /*color: #4e629d !important;*/
  /*background-color: #f2faff !important;*/
  border-color: #026bff !important;
  box-shadow: 0 2px 5px 0 #6db7ff !important;
}

.btn-low-no-shadow {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-style: solid !important;
  /*border-color: #d2d2d6 !important;*/
  border-width: 1px !important;
  color: #337dff !important;
  font-size: small !important;
  /*color: #4e629d !important;*/
  /*background-color: #f2faff !important;*/
  border-color: #026bff !important;
}

.btn-warn {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #FFCDD2 !important;
  border-color: #EF5350 !important;
  border-style: solid !important;
  border-width: 1px !important;
  color: #D50000 !important;
  font-size: small !important;

  &:hover {
    color: #D50000 !important;
    background-color: #FFCDD2 !important;
    border-color: #EF5350 !important;
    box-shadow: 0 2px 5px 0 #B71C1C !important;
  }

  &:active {
    background-color: #EF9A9A !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #ffffff !important;
  }

  &:focus {
    background-color: #FFCDD2 !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #EF9A9A !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #7095c8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}


.triangle-right-sm {
  width: 0;
  height: 0;
  /*border-top: 25px solid transparent;
  border-left: 50px solid #555;
  border-bottom: 25px solid transparent;*/
  border-top: 3px solid transparent;
  border-left: 6px solid #555;
  border-bottom: 3px solid transparent;
}

.pointer {
  cursor: pointer;
}

.mat-dialog-container {
  padding: 0 !important;
}

.custom-dialog-container .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 1.5em !important;
  /*width: 100% !important;*/
}

.custom-dialog-container-resize .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 1.5em !important;
  resize: both;
}
.custom-dialog-container-resize-hor .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 1.5em !important;
  resize: horizontal;
}
.custom-dialog-container-resize-vert .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 1.5em !important;
  resize: vertical;
}
.custom-dialog-container3 .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 0.5em !important;
  /*width: 100% !important;*/
}
.custom-dialog-container4 .mat-dialog-container {
  background-color: #f0f0f0;
  padding: 1em !important;
  /*width: 100% !important;*/
}
.custom-dialog-container4b .mat-dialog-container {
  background-color: #f8f8f8;
  padding: 1em !important;
  /*width: 100% !important;*/
}
.custom-dialog-container4w .mat-dialog-container {
  background-color: white;
  padding: 1em !important;
  /*width: 100% !important;*/
}

.custom-dialog-container2 .mat-dialog-container {
  background-color: white;
  padding: 1.5em !important;
  /*width: 100% !important;*/
}

.custom-dialog-container5 .mat-dialog-container{
  background-color: #f0f0f0;
  padding: 0em 0.5em 0.5em 0.5em !important;
}

.tooltipLineBreak {
  /*white-space: pre-line !important;*/
  white-space: pre-wrap !important;
}


.mat-row:nth-child(even) {
  background-color: #f8f8f8;
}

.mat-row:nth-child(odd) {
  background-color: white;
}

.custom-mat-select-panel {
  min-width: 15em !important;
  margin-left: 2.75em;
  margin-top: 2.3em;
}

.custom-mat-select-panel2 {
  min-width: 18em !important;
  margin-left: 2.75em;
  margin-top: 2.3em;
}

.custom-mat-select-panel3 {
  min-width: 7.5em !important;
  margin-left: 1em !important;
  margin-top: 1.5em !important;
}

.custom-mat-select-panel4 {
  min-width: 28em !important;
  margin-left: 2.75em;
  margin-top: 2.3em;
}

.custom-mat-select-panel5 {
  min-width: 80em !important;
  margin-left: 2.75em;
  margin-top: 2.3em;
}

.custom-mat-select-panel6 {
  min-width: 51em !important;
  margin-left: -2em;
  margin-top: 2.4em;
}

.custom-mat-select-panel7 {
  min-width: 12em !important;
  margin-left: 2.85em;
  margin-top: 2.4em;
}


.custom-mat-select-panel8 {
  min-width: 12em !important;
  margin-left: 2.85em;
  margin-top: 2.7em;
}
/*.mat-checkbox-inner-container {
  width: 1em !important;
  height: 1em !important;
  font-size: small;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {*/
/*background-color: white;*/
/*background-color: transparent;*/
/*transform: scale(0.75);*/
/*}

.mat-checkbox .mat-checkbox-checkmark-path {*/
/*stroke: green !important;*/
/*stroke: black !important;
}

.mat-checkbox .mat-checkbox-frame {
  border-width: 0.0625em;
}*/
.popover-header {
  font-size: small !important;
}

.popover-body {
  font-size: small !important;
}

.mat-button, .mat-flat-button, .mat-stroked-button, .mat-raised-button {
  &.cdk-program-focused .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}



.mat-button, .mat-flat-button, .mat-stroked-button, .mat-raised-button {
  line-height: 1.75 !important;
}

.mat-button.larger, .mat-flat-button.larger, .mat-stroked-button.larger, .mat-raised-button.larger {
  line-height: 2.5 !important;
}


.mat-button.grid, .mat-flat-button.grid, .mat-stroked-button.grid, .mat-raised-button.grid {
  line-height: 1.5625 !important;
  font-size: x-small !important;
  min-width: 1em !important;
}

.mat-button.narrow, .mat-flat-button.narrow, .mat-stroked-button.narrow, .mat-raised-button.narrow {
  line-height: 1.5 !important;
  padding-right: .25em !important;
  padding-left: .25em !important;
  font-weight: normal !important
}

.mat-select-placeholder {
  color: black;
}

.mat-tooltip {
  //background-color: darkblue;
  font-size: small !important;
  /*max-width: unset !important;*/
  max-width: 46.5em !important;
  white-space: pre-wrap;
}

tr.mat-row:hover {
  background-color: #d3d3d3;
}

.mat-row:hover {
  background-color: #d3d3d3;
}

td.mat-cell {
  vertical-align: top !important;
}

.mat-cell {
  vertical-align: top !important;
}

.scroll-thin {
  overflow-y: scroll;
  /* for firefox */
  scrollbar-width: thin;
  scrollbar-color: #9b9b9b transparent;
  /* for firefox end */
}

/* for Chrome, Edge, and Safari */
.scroll-thin::-webkit-scrollbar {
  width: 0.5em;
}
/* for Chrome, Edge, and Safari end*/

.scroll-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-thin::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 1.25em;
  border: transparent;
}

.input-noborder {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.input-noborder.input:focus {
  outline: none;
}

.ck-editor__editable_inline {
  height: 27em;
  width: 64.3em;
  border: lightgrey 0.0625em solid !important;
  /*background-color: #eaf0fd !important;*/
  background-color: #ffffff !important;
}

.ck-editor__editable_inline p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.btn-green {
  border: black solid 0.0625em !important;
  background-color: #c7e8ac;
  padding-left: 0.25em !important;
  padding-right: 0.25em !important;
  font-weight: 500;
  font-size: small;
}

.mat-datepicker-content .mat-calendar {
  zoom: 0.70;
  padding: 0em !important;
}

.mat-checkbox-smaller .mat-checkbox-inner-container {
  width: 1em;
  height: 1em;
}

.mat-option-smaller .mat-pseudo-checkbox {
  width: 1em;
  height: 1em;
}

.mat-option-smaller .mat-pseudo-checkbox-checked::after {
  /*top: 12.4px !important;
  left: 1px !important;
  width: 24px !important;*/
  top: 0em !important;
  left: -0.135em !important;
  width: 1em !important;
}


.btn-low-no-border {
  padding: 0 1em 0 1em !important;
  font-weight: 500 !important;
  background-color: #ffffff !important;
  border-style: none !important;
  color: #337dff !important;
  font-size: small !important;

  &:hover {
    color: #4e629d !important;
    background-color: #f2faff !important;
    border-color: none !important;
  }

  &:active {
    background-color: #337dff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #ffffff !important;
  }

  &:focus {
    background-color: #f2faff !important;
    outline-style: solid !important;
    outline-color: #7095c8 !important;
    outline-width: 2px !important;
    color: #337dff !important;
  }

  &:disabled {
    background-color: #e5e5e5 !important;
    border-color: #7095c8 !important;
    border-style: solid !important;
    border-width: 1px !important;
    color: #999999 !important;
  }
}

.red-snackbar {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.green-snackbar {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.valid-info {
  border-color: #FF9933;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF9933' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF9933' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.valid-info:focus {
  border-color: #FF9933;
  box-shadow: 0 0 0 .2rem rgba(255, 153, 51,.25)
}

.custom-mat-group-smaller .mat-tab-label {
  width: 2.75em;
}

.remove-mat-tab-border-bottom .mat-tab-header {
  border-bottom: none;
}

.mat-tooltip.custom-mat-tooltip {
  font-size: small !important;
  min-width: 15em !important;
  max-width: fit-content !important;
  white-space: nowrap;
}


.ck.ck-content p.email-body {
  font-family: 'Courier New';
  margin: 0in;
  font-size: 11.0pt;
}

.ck.ck-content p.signature {
  font-family: Tahoma;
  margin: 0in;
  font-size: 9.0pt;
}

.ck.ck-content p {
  margin: 0in;
  font-size: 11.0pt;
}


:root, [data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13,110,253;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-primary-text: #0a58ca;
  --bs-secondary-text: #6c757d;
  --bs-success-text: #146c43;
  --bs-info-text: #087990;
  --bs-warning-text: #997404;
  --bs-danger-text: #b02a37;
  --bs-light-text: #6c757d;
  --bs-dark-text: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #f8f9fa;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #e9ecef;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0,0,0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33,37,41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233,236,239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33,37,41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248,249,250;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255,255,255;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13,110,253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10,88,202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(var(--bs-body-color-rgb), 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(var(--bs-body-color-rgb), 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(var(--bs-body-color-rgb), 0.075);
  --bs-emphasis-color: #000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff3cd;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px
}

[data-bs-theme=dark] {
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173,181,189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33,37,41;
  --bs-emphasis-color: #f8f9fa;
  --bs-emphasis-color-rgb: 248,249,250;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173,181,189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52,58,64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173,181,189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43,48,53;
  --bs-emphasis-color: #fff;
  --bs-primary-text: #6ea8fe;
  --bs-secondary-text: #dee2e6;
  --bs-success-text: #75b798;
  --bs-info-text: #6edff6;
  --bs-warning-text: #ffda6a;
  --bs-danger-text: #ea868f;
  --bs-light-text: #f8f9fa;
  --bs-dark-text: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #212529;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #495057;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #055160;
  --bs-warning-border-subtle: #664d03;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: #fff;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #9ec5fe;
  --bs-link-color-rgb: 110,168,254;
  --bs-link-hover-color-rgb: 158,197,254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15)
}




.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a58ca'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle)
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition)
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button {
    transition: none
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform)
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition)
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button::after {
    transition: none
  }
}

.accordion-button:hover {
  z-index: 2
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow)
}

.accordion-header {
  margin-bottom: 0
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color)
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius)
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:not(:first-of-type) {
  border-top: 0
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius)
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius)
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x)
}

.accordion-flush .accordion-collapse {
  border-width: 0
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0
}

.accordion-flush .accordion-item:first-child {
  border-top: 0
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.ngb-tooltip .tooltip-inner {
  font-size: small !important;
  min-width: 15em !important;
  max-width: fit-content !important;
  white-space: nowrap;
  text-align: left !important;
  background-color: lightgray;
  color: black;
}

.ngb-tooltip-small .tooltip-inner {
  font-size: x-small !important;
}


.ngb-tooltip-instructions .tooltip-inner {
  font-size: small !important;
  max-width: 50em !important;
  //max-width: fit-content !important;
  white-space: pre-wrap;
  text-align: left !important;
  background-color: dimgray;
 // color: black;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 1.75;
  font-size: small;
}

.mat-button-toggle-checked {
  background-color: #337dff;
  color: #fff !important;
}



.model-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto; 
 
 
}

.model-content.minimized {
  display: none; 
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  
}

.minimizedDialog {
  position: absolute;
  right: -6%;
  bottom: 1%;
  transform: translateX(-50%);
  width: 240px;
   background-color: #f0f0f0;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
  text-align: center;
}


.black-spinner {
  width: 20px;
  height: 20px;
  margin-top: -0.5em;
}

.spinner-path {
  animation: rotate 1.5s linear infinite;
  transform-origin: center center;
  stroke-dasharray: 80;
  stroke-dashoffset: 0;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.mat-option.small-option {
  line-height: 1.5 !important;
  height: 1em !important;
  padding: 0 .5em !important;
}

.mat-option-text.small-option {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
}


.material-symbols-outlined.filled {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0;
}

.divImmediatePriority {
  background-color: #db3333;
  color: white;
  padding: 0.05em 0.3em;
  border-radius: 0.65em
}